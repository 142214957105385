import React from 'react'
import useIsMobile from '@hooks/useIsMobile'
import SquaredLinkComponent from '@components/SquaredLinkComponent'
import { Caption, Heading, Paragraph } from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import Spacer from '@components/SpacerComponent'

import { FadeIn } from '@components/Animations'
import SpacerComponent from '@components/SpacerComponent'

type CopyProps = {
  imageIsFirst: boolean
  caption: string
  heading: string
  paragraph?: string
  href?: string // External Link
  to?: string // Internal Link
}

export const Copy = (props: CopyProps) => {
  const isMobile = useIsMobile()
  const headingColumn = isMobile
    ? '1 / 13'
    : props.imageIsFirst
    ? '1 / 6'
    : '2 / 7'

  return (
    <Grid.SubGrid
      span={isMobile ? 'full' : 'half'}
      order={isMobile ? 2 : 2}
      alignSelf='center'
    >
      <Grid.Item column={headingColumn} alignSelfStart>
        <FadeIn>
          <Caption>{props.caption}</Caption>
          <SpacerComponent bottom='xs' />
          <Heading>{props.heading}</Heading>
          <SpacerComponent bottom='xs' />
          <Paragraph size='sm'>{props.paragraph}</Paragraph>
        </FadeIn>
      </Grid.Item>
      <Grid.Item column={headingColumn}>
        <Spacer top={isMobile ? 'sm' : 'lg'} />

        <FadeIn>
          <SquaredLinkComponent
            href={props.href}
            to={props.to}
            label='Find out more'
          />
        </FadeIn>
        <Spacer bottom={isMobile ? 'xl2' : 'lg'} />
      </Grid.Item>
    </Grid.SubGrid>
  )
}
