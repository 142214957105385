import React from 'react'
import styled from 'styled-components'

// Hooks
import useIsMobile from '@hooks/useIsMobile'

// Assets
// @ts-expect-error
import temp from '@assets/images/temp-headshot.jpg'
// @ts-expect-error
import Peter from '@assets/images/peter-headshot.jpg'
// @ts-expect-error
import Harri from '@assets/images/harri-headshot.jpg'
// @ts-expect-error
import Nick from '@assets/images/nick-headshot.jpg'
// @ts-expect-error
import { coproLogo } from '@assets/'

// Components
import SectionComponent from '@components/SectionComponent'
import { Author, Quote, VerticalHeading } from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import SpacerComponent from '@components/SpacerComponent'
import { AnimatePresence, motion } from 'framer-motion'

// TODO: Add aria live

export type QuoteType = {
  copy: string
  avatar: any
  org: string
  name: string
}

type ContentProps = {
  copy: string
  name: string
  key: string
  image: string
}

const QuoteBounceAnimation: React.FC = props => (
  <motion.div
    initial={{ y: 300, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -300, opacity: 0 }}
  >
    {props.children}
  </motion.div>
)

const Content = (props: ContentProps) => {
  const isMobile = useIsMobile()

  return (
    <>
      <Grid.Item column='3 / 12'>
        <QuoteBounceAnimation>
          <Quote font='display' aria-live='polite' aria-relevant='all'>
            {`“${props.copy}”`}
          </Quote>
          <SpacerComponent bottom={'sm'} />
        </QuoteBounceAnimation>
      </Grid.Item>
      {/* Credit Section */}
      <Grid.Row>
        <Grid.Item alignSelfCenter column='1 / 8'>
          <QuoteBounceAnimation>
            <Author>{props.name}</Author>
          </QuoteBounceAnimation>
        </Grid.Item>
        <Grid.Item column='10 / 13'>
          <QuoteBounceAnimation>
            <Avatar src={props.image} isHidden={isMobile} />
          </QuoteBounceAnimation>
        </Grid.Item>
      </Grid.Row>
    </>
  )
}
type QuotesProps = {
  quotes: QuoteType[]
}
const Quotes = (props: QuotesProps) => {
  const [active, setActive] = React.useState<number>(0)

  const isMobile = useIsMobile()
  const data = props.quotes
  const activeQuote = data?.[active]

  return (
    <SectionComponent>
      <Grid.Row
        role='tablist'
        aria-controls='quotes-tab-controls'
        aria-owns={data.map((x, i) => `quote-tab-${i}`).join(' ')}
        id='quotes-tablist'
        aria-expanded='true'
        hidden={false}
      >
        {/* Left Section */}
        <Grid.Item column='1 / 1' alignSelfStart justifySelfStart>
          <VerticalHeading>Our Awesome Partners</VerticalHeading>
          <Dots id='quotes-tab-controls'>
            {data.map((_, i) => {
              return (
                <Dot
                  key={`tab-control-${i}`}
                  active={active === i}
                  onClick={e => setActive(i)}
                >
                  <TabControl
                    role='tab'
                    id={`quote-tab-${i}`}
                    data-state={active === i ? 'active' : 'inactive'}
                    aria-controls='quotes-tablist'
                    aria-selected={active === i ? 'true' : 'false'}
                    aria-label={`Show quote ${i + 1}`}
                  />
                </Dot>
              )
            })}
          </Dots>
        </Grid.Item>

        {/* Main Quote Text */}
        <Grid.Item column={isMobile ? '3 / 13' : '3 / 12'}>
          <AnimatePresence exitBeforeEnter>
            <Content
              key={`tab-control-${active}`}
              copy={activeQuote.copy}
              name={`${activeQuote.name}`}
              image={activeQuote.avatar}
            />
          </AnimatePresence>
        </Grid.Item>
      </Grid.Row>
    </SectionComponent>
  )
}

const Avatar = styled.div<{ isHidden: boolean; src: string }>`
  width: 100%;
  padding-top: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadow.md};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`

const Dots = styled.ol`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
`
type DotProps = {
  active?: boolean
}
const Dot = styled.li<DotProps>`
  background-color: ${({ theme, active }) =>
    active ? theme.colors.darkPurple.default : theme.colors.darkPurple.light};
  transition: background-color 0.5s ease-in;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  &:hover {
    opacity: 0.75;
    transition: opacity 0.1s ease-in;
  }

  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: opacity 0.05s;

  display: grid;
  place-items: stretch;

  &[data-state='inactive'] {
    background-color: ${({ theme }) => theme.colors.gray.light};
  }
`

const TabControl = styled.button`
  border: unset;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: transparent;
`

const QuotesWithData = () => {
  const quotes: QuoteType[] = [
    {
      copy: 'Big Lemon have been the perfect partners for us. They understand the challenges of building a tech startup and are always happy to talk through ways to build MVPs and test ideas quickly. We are like one big team working toward the same goal.',
      avatar: Peter,
      org: 'Agile Kinetic',
      name: 'Peter Bishop, Agile Kinetic',
    },
    {
      copy: 'To be honest, we didn’t quite know how brilliant this partnership was going to be, but we’re so glad we’ve found each other! Big Lemon aren’t just our web developers, they’ve become part of the Co-Production Collective community.',
      avatar: coproLogo,
      org: 'The Co-Production Collective',
      name: 'Co-Production Collective',
    },
    {
      copy: 'What we wanted to do was no small feat, typically of FABRIC our idea was unique but the team at Big Lemon have walked alongside us each step of the way, embracing our ideas, translating our language into their language and supporting us to develop our understanding of tech.',
      avatar: Harri,
      org: 'FABRIC',
      name: 'Harri Helvon-Hardy, FABRIC',
    },
    {
      copy: 'Big Lemon were the ideal tech partners for a project like this. We wanted to work with a business that understands the importance of tech for good and who would share our values. The expertise, ability to dig deep into the numbers, and enthusiasm has helped to fuel the success of the TSEP project.',
      avatar: Nick,
      org: 'Amber Energy',
      name: 'Nick Proctor, Amber Energy',
    },
  ]

  return <Quotes quotes={quotes} />
}

export default QuotesWithData
