import React from 'react'
import { motion, HTMLMotionProps } from 'framer-motion'
import styled from 'styled-components'

export type ImageSource = {
  srcSet: any
  type: 'image/webp' | 'image/jpeg' | 'image/png'
}

export type ImageComponentProps = HTMLMotionProps<'img'> & {
  sources?: ImageSource[]
  alt: string
  portrait?: boolean
}

export const ImageComponent: React.FC<ImageComponentProps> = props => {
  const imageSrc = { ...props, sources: undefined }

  return (
    <picture>
      {props.sources?.map(source => {
        return <source {...source} />
      })}
      <Image {...imageSrc} />
    </picture>
  )
}

const Image = styled(motion.img)<{ alt: string; portrait?: boolean }>`
  width: 100%;
  max-width: 100%;
  height: ${({ portrait }) => (portrait ? '50vw' : 'initial')};
  max-height: ${({ portrait }) => (portrait ? '60vh' : 'initial')};
  box-shadow: ${props => props.theme.shadow.lg};
  border-radius: ${({ theme }) => theme.spacing.xs};
  object-position: center;
  object-fit: cover;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: initial;
    max-height: initial;
  }
`

export default ImageComponent
