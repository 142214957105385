import React from 'react'

// Components
import { InvertingImageBlock, InvertingImageBlockProps } from './components'

type InvertingImageBlockDatum = Omit<InvertingImageBlockProps, 'index'> & {
  id: string
}

type InvertingImageSectionProps = {
  data: InvertingImageBlockDatum[]
  portrait?: boolean
}

const InvertingImageSection: React.FC<InvertingImageSectionProps> = props => {
  return (
    <>
      {props.data.map((item, i) => {
        return (
          <InvertingImageBlock
            key={item.id}
            index={i}
            {...{ ...item, id: undefined }}
          />
        )
      })}
    </>
  )
}
export default InvertingImageSection
