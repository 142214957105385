import React from 'react'

// Components
import Grid from '@components/ViewGridComponent'
import Spacer from '@components/SpacerComponent'

import { Copy } from './Copy'
import { Image } from './Image'
import { ImageComponentProps } from '@components/ImageComponent'

export type InvertingImageBlockProps = {
  index: number
  heading: string
  caption: string
  paragraph?: string
  image: ImageComponentProps
  href?: string // External Link
  to?: string // Internal Link
}

export const InvertingImageBlock: React.FC<InvertingImageBlockProps> =
  props => {
    const imageIsRight = !(props.index % 2)

    return (
      <Grid.Row>
        <Copy
          imageIsFirst={imageIsRight}
          caption={props.caption}
          heading={props.heading}
          paragraph={props.paragraph}
          href={props.href}
          to={props.to}
        />

        <Image imageIsFirst={imageIsRight} {...props.image} />

        <Spacer bottom='xl' />
      </Grid.Row>
    )
  }
