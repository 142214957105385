import React from 'react'
import { FadeIn } from '../Animations'

import SquaredLinkComponent from '../SquaredLinkComponent'
import { Heading, Paragraph, VerticalHeading } from '../Typography'
import Grid from '../ViewGridComponent'
import Spacer from '../SpacerComponent'
import styled from 'styled-components'

import useIsMobile from '@hooks/useIsMobile'

type HeadingSectionComponentType = {
  verticalHeading?: string
  heading: React.ReactNode
  content: React.ReactNode[]
  linkLabel?: string
  linkHref?: string
  linkTo?: string
  hasLink: boolean
  useAnchor?: boolean
  isSubSection?: boolean
  ref?: React.MutableRefObject<any>
}

const HeadingSectionComponent: React.FC<HeadingSectionComponentType> =
  props => {
    const isMobile = useIsMobile()

    const verticalHeadingColumn = props.verticalHeading ? '1 / 1' : '1 / 1'
    const contentColumn = props.verticalHeading
      ? isMobile
        ? '3 / 13'
        : '3 / 11'
      : isMobile
      ? '1 / 13'
      : '3 / 11'

    return (
      <Grid.Row>
        {props.verticalHeading && (
          <Grid.Item
            column={verticalHeadingColumn}
            alignSelfStretch
            justifySelfStart
          >
            <FadeIn>
              <VerticalHeading>{props.verticalHeading}</VerticalHeading>
            </FadeIn>
          </Grid.Item>
        )}

        <Grid.Item
          column={contentColumn}
          // @ts-expect-error
          css={`
            display: flex;
            flex-direction: column;
            align-items: ${isMobile ? 'left' : 'center'};
          `}
        >
          {typeof props.heading === 'string' ? (
            <FadeIn>
              <Heading centered={!isMobile}>{props.heading}</Heading>
            </FadeIn>
          ) : (
            props.heading
          )}
          {/* If its a string, render as paragraph, otherwise pass the node */}
          {props.content.map((item, i) =>
            typeof item === 'string' ? (
              <FadeIn>
                {/* if its the first item (under header) use lg, sm between content items */}
                <Spacer top={i === 0 ? 'lg' : 'sm'}>
                  <Paragraph
                    centered={!isMobile}
                    ref={props.ref}
                    key={'heading-section-' + i}
                    size='md'
                  >
                    {item}
                  </Paragraph>
                </Spacer>
              </FadeIn>
            ) : (
              item
            )
          )}
        </Grid.Item>
        {props.hasLink && (
          <Grid.Item column={contentColumn}>
            <FadeIn>
              <FlexCenter>
                <Spacer top='lg'>
                  <SquaredLinkComponent
                    label={props.linkLabel ?? ''}
                    href={props.linkHref}
                    to={props.linkTo}
                    useAnchor={props.useAnchor}
                  />
                </Spacer>
              </FlexCenter>
            </FadeIn>
          </Grid.Item>
        )}
      </Grid.Row>
    )
  }

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export default HeadingSectionComponent
