import * as React from 'react'
import Spacer, { PaddingValue } from '@components/SpacerComponent'
import useIsMobile from '@hooks/useIsMobile'

type ImageSpacerProps = {
  disableLeftPadding?: boolean
  disableRightPadding?: boolean
}

export const ImageSpacerComponent: React.FC<ImageSpacerProps> = ({
  children,
  disableLeftPadding = false,
  disableRightPadding = false,
}) => {
  const isMobile = useIsMobile()

  const isBidirectional = !(disableLeftPadding || disableRightPadding)

  const value: PaddingValue = isMobile ? 'xs' : 'sm'
  /* This just allows us to disable either of the sides (e.g., just left or right padding) */
  const spacing = {
    horizontal: isBidirectional ? value : undefined,
    left: !disableLeftPadding ? value : undefined,
    right: !disableRightPadding ? value : undefined,
  }

  return (
    <Spacer
      horizontal={spacing.horizontal}
      left={spacing.left}
      right={spacing.right}
    >
      {children}
    </Spacer>
  )
}

export default ImageSpacerComponent
