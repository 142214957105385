import * as React from 'react'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import drawing from '../../animations/Drawing.json'
import styled from 'styled-components'

const LemonAnimation: React.FC<{ shouldAnimate: boolean; className?: string }> =
  props => {
    const lottieRef = React.useRef<LottieRefCurrentProps>(null)

    React.useEffect(() => {
      if (props.shouldAnimate && lottieRef.current) {
        lottieRef.current.play()
      }
    }, [props.shouldAnimate])

    return (
      <Lottie
        lottieRef={lottieRef}
        loop={false}
        animationData={drawing}
        autoplay={false}
      />
    )
  }

export const StyledLemonDudeAnimation = styled(LemonAnimation)`
  max-width: 50vw;
  margin: 0 auto;
`

export default StyledLemonDudeAnimation
