import React from 'react'
import useIsMobile from '@hooks/useIsMobile'
import { FadeAndRotate } from '@components/Animations'
import Grid from '@components/ViewGridComponent'
import Spacer from '@components/SpacerComponent'
import ImageSpacerComponent from '@components/ImageSpacerComponent'
import { ImageComponent, ImageComponentProps } from '@components/ImageComponent'

export type ImageProps = ImageComponentProps & {
  imageIsFirst: boolean
}

export const Image = (props: ImageProps) => {
  const isMobile = useIsMobile()
  const imageProps = { ...props, imageIsFirst: undefined }

  const imageIsFirst = props.imageIsFirst
  let order = imageIsFirst ? 2 : 1
  let imageColumn = imageIsFirst ? '7 / 13' : '1 / 7'

  if (isMobile) {
    // Add all the mobile overrides in this block
    imageColumn = '1 / 13'
    order = 1
  }

  return (
    <Grid.Item order={order} column={imageColumn}>
      <FadeAndRotate direction={imageIsFirst ? 'right' : 'left'}>
        <ImageSpacerComponent>
          <ImageComponent {...imageProps} />
        </ImageSpacerComponent>
      </FadeAndRotate>
      <Spacer bottom='lg' />
    </Grid.Item>
  )
}
